import "/global.scss";

// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getFirestore, collection } from "firebase/firestore";
import { getFunctions } from "firebase/functions";
import { getAuth } from "firebase/auth";

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyCllLcTPlPfZnKO2agbymYfR5-c2ZOm0L0",
  authDomain: "dienstplan-ea810.firebaseapp.com",
  databaseURL: "https://dienstplan-ea810.firebaseio.com",
  projectId: "dienstplan-ea810",
  storageBucket: "dienstplan-ea810.appspot.com",
  messagingSenderId: "423111874572",
  appId: "1:423111874572:web:ef9f67da0136ce7493f629",
};

// Initialize Firebase
export const app = initializeApp(firebaseConfig);

//Initialize Firestore
export const db = getFirestore(app);

//Initialize Functions
export const functions = getFunctions();

//Initialize Auth
export const auth = getAuth();

//Definitions
export const dateTimeFormat = new Intl.DateTimeFormat("de", {
  weekday: "short",
  day: "2-digit",
  month: "long",
});

//DB ref definitions
export const usersRef = collection(db, "users");
export const dienstplanRef = collection(db, "dienstplan");
//export function kalenderDocRef(doc:DocumentReference<DocumentData>) return collection(doc,"kalender");

//global functions
export function createLink(
  href: string,
  text: string,
  classes: string[]
): HTMLAnchorElement {
  let a = document.createElement("a");
  a.href = href;
  a.innerText = text;
  a.classList.add(...classes);
  return a;
}
